import * as React from 'react'
import { useForm, ValidationError } from "@formspree/react";
import BackArrow from '../../components/back-arrow'
import { centerHeading } from '../../components/layout.module.css'
import { newsletterContainer } from '../../components/newsletter.module.css'

const NewsForm = () => {
  const [state,handleSubmit] = useForm("mwkylbnl");
  if (state.succeeded) {
    return <div><p>Welcome aboard!</p><p>If you're having trouble finding the email, check your spam or trash folders.</p></div>
  }
  return (
    <form onSubmit={handleSubmit}>
      <input id="email" type="email" name="email" placeholder="ex: your@email.com" /><br/>
      <ValidationError prefix="Email" field="email" errors={state.errors} />
      <button type="submit" disabled={state.submitting}>&nbsp;Submit&nbsp;</button>
    </form>
  )
}

const NewsletterPage = () => {
  return (
    <main>
      <title>Newsletter &ndash; Evan Gedrich</title>
      <h1 className={centerHeading}>newsletter@</h1>
      <div className={newsletterContainer}>
        <p>This is a project for interpreting and reflecting on articles and books
        I read and find interesting, meant to facilitate engaged learning and
        improve knowledge retention. It's essentially an educational blog in email
        form, with the occasional general purpose update from yours truly.</p>
        <p>So if you're interested in learning new things along with your pal Evan,
        or at least think your inbox might not hate hearing from me on occasion,
        simply submit your email below.</p>
        <NewsForm /><br/>
        <h2>Archive</h2>
        <p>No past newsletters available.</p><br/>
      </div>
      <BackArrow to="/apps">Apps</BackArrow>
    </main>
  )
}

export default NewsletterPage
